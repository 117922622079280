.background {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  background: black;
}

 .logoWrapperForm {
  transition: all 0.7s ease-in;
}

.App {
  position: relative;
  height: 100vh;
  overflow: scroll;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.logoWrapper{
  display:flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
@media all and (min-width:160px) and (max-width: 600px) {
  .logoWrapper{
    display:flex;
    z-index: 99;
  }
}

.logoWrapperForm{
  display:flex;
  height:10vh;
  width: 80%;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 99;
}

.logo{ 
  max-width: 620px;
  display:flex;
  /* padding:20px; */
  z-index: 99;
  transition: width 2s;
}

@media all and (min-width:160px) and (max-width: 600px) {
  .logo{
    max-width: 320px;

  }
}

.logoForm{ 
  max-width: 400px;
  display:flex;
  padding:20px;
  z-index: 99;
  transition: width 2s;
}

.stop-button {
  position: absolute;
  bottom: 20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  /* Add more styling as per your design */
}

.signup-form h2 {
  color:#fff;
}

.signup-card {
  border:#1a1a75 solid 2px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  padding:15px;
  width:300px;
  height:300px;
  display:flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  background: #020319;
  position: absolute;
  border-radius: 10px;
  bottom: 30px;
  left: 12%;
  transform: translateX(-50%);
}

@media all and (min-width:160px) and (max-width: 600px) {
  .signup-card {
    bottom: 30px;
    left: 15%;
    transform: translateX(0%);
  }
}

.signup-card .title {
  color:#fff;
  font-weight: bold;
  font-size: 25px;
  margin:0px;
}

.signup-card .body {
  color:#fff;
  font-size: 15px;
  margin:20px 0px 30px 0px;
}

.signup-button {
  display:flex;
  align-items: center;
  justify-content: center;
  background: #5D5DFF;
  border-radius: 10px;
}

.signup-button:hover {
  cursor:pointer;
}

.upsale-button {
  position: relative;
  z-index: 0;
  height: 40px;
  overflow: hidden;
  border: none !important;
  padding: 0 !important;
  background: #5D5DFF !important;
  color: #656565;
  background: white;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  box-shadow: 0 4px 50px 0 rgb(0 0 0 / 7%);
  transition: .2s all linear;
  text-decoration: initial;
}

.upsale-button span{
  position: relative;
  z-index: 1;
  height: calc( 100% - 4px );
  width: calc( 100% - 4px );
  top: 2px;
  left: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  color: white;
  background: #5D5DFF;
}

.upsale-button:after{
 content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(90deg, rgba(255,255,255,0)    0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
}


@-webkit-keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}



.shape1 {
  position: absolute;
  top: 42%;
  left: 70%;
  width: 25rem;
  height: 25rem;
  background: #282893;
  border-radius: 24% 76% 77% 23% / 30% 30% 70% 70%;
  /* filter: blur(6rem); */
  animation: shape1 20s infinite;
}

@keyframes shape1 {
  0% {
    transform: translate(0, 0);
    border-radius: 24% 76% 77% 23% / 30% 30% 70% 70%;
  }
  25% {
    transform: translate(-55%, -10%);
    border-radius: 28% 72% 81% 19% / 78% 25% 75% 22%;
    height: 50rem;
    width: 80rem;
  }
  50% {
    transform: translate(-90%, -10%);
    border-radius: 60% 40% 0% 100% / 1% 74% 26% 99%;
  }
  75% {
    transform: translate(-30%, -10%);
    border-radius: 0% 100% 100% 0% / 0% 100% 100% 0%;
    height: 30rem;
    width: 45rem;
  }
  100% {
    transform: translate(0%, 0%);
    border-radius: 24% 76% 77% 23% / 30% 30% 70% 70%;
  }
}

.shape2 {
  position: absolute;
  top: 33%;
  left: 40%;
  width: 35rem;
  height: 25rem;
  background: rgb(6, 20, 54);
  border-radius: 73% 27% 77% 23% / 53% 4% 96% 47%;
  /* filter: blur(12rem); */
  animation: shape2 35s infinite;
}

@keyframes shape2 {
  0% {
    transform: translate(0, 0);
    border-radius: 73% 27% 77% 23% / 53% 4% 96% 47%;
  }
  25% {
    transform: translate(-55%, -55%);
    border-radius: 28% 72% 81% 19% / 78% 25% 75% 22%;
    height: 50rem;
    width: 50rem;
  }
  50% {
    transform: translate(-70%, -70%);
    border-radius: 60% 40% 0% 100% / 1% 74% 26% 99%;
  }
  75% {
    transform: translate(-30%, -30%);
    border-radius: 0% 100% 100% 0% / 0% 100% 100% 0%;
  }
  100% {
    transform: translate(0%, 0%);
    border-radius: 73% 27% 77% 23% / 53% 4% 96% 47%;
  }
}

.shape3 {
  position: absolute;
  top: 20%;
  left: 80%;
  width: 25rem;
  height: 25rem;
  background: rgb(5, 9, 43);
  border-radius: 70% 30% 80% 20% / 42% 25% 75% 58%;
  filter: blur(14rem);
  animation: shape3 35s infinite;
}

@keyframes shape3 {
  0% {
    transform: translate(0, 0);
    border-radius: 70% 30% 80% 20% / 42% 25% 75% 58%;
  }
  25% {
    transform: translate(-125%, -25%);
    border-radius: 28% 72% 81% 19% / 78% 25% 75% 22%;
    height: 50rem;
    width: 50rem;
  }
  50% {
    transform: translate(-90%, -35%);
    border-radius: 60% 40% 0% 100% / 1% 74% 26% 99%;
  }
  75% {
    transform: translate(-30%, -40%);
    border-radius: 0% 100% 100% 0% / 0% 100% 100% 0%;
  }
  100% {
    transform: translate(0%, 0%);
    border-radius: 70% 30% 80% 20% / 42% 25% 75% 58%;
  }
}

.shape4 {
  position: absolute;
  top: 42%;
  left: 70%;
  width: 25rem;
  height: 25rem;
  background: #5D5DFF;
  border-radius: 24% 76% 77% 23% / 30% 30% 70% 70%;
  filter: blur(5rem);
}

.shape5 {
  position: absolute;
  top: 33%;
  left: 40%;
  width: 35rem;
  height: 25rem;
  background: rgb(6, 11, 38);
  border-radius: 73% 27% 77% 23% / 53% 4% 96% 47%;
  filter: blur(5rem);
}



form {
  display:flex;
  flex-direction: column;
}

.signup-form button {
  margin:20px 0px;
}

.detail-container img{
  max-width: 50%!important;
}

/* accessibility check */
.signup-form {
  background-color: #020319; /* Dark background */
  color: #fff; /* Light text for contrast */
  font-family: Arial, sans-serif; /* Readable font */
  padding: 20px;
  border-radius: 8px;
  max-width: 650px;
  margin:100px auto;
  border:#1a1a75 solid 2px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  flex-direction: column;
  background: #020319;
  border-radius: 10px;
}

.typewriter {
  color: #fff; /* Light text for contrast */
  font-family: Arial, sans-serif; /* Readable font */
  padding: 10px;
  border-radius: 8px;
  max-width: 50%;
  margin: 0 auto;
  z-index: 999;
  position:relative;
  font-size:25px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

@media all and (min-width:160px) and (max-width: 600px) {
  .typewriter{
    font-size:20px;
    padding: 5px;
    max-width: 90%;
  }
}

/* Input field styling */
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  background-color: #555; /* Slightly lighter than form background */
  color: #fff; /* Light text */
  border: 1px solid #777; /* Slightly contrasting border */
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 16px; /* Adequate font size for readability */
}

/* Input focus state for better visibility */
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus {
  outline: none;
  border-color: #aaa; /* More visible border on focus */
  box-shadow: 0 0 3px #aaa; /* Adding a glow effect for better focus visibility */
}

/* Labels for form fields */
label {
  display: block;
  margin-top:10px;
  margin-bottom: 5px;
  font-weight: bold;
}

.details {
  display: block;
}

.block-1 {
  display:flex;
  flex-direction:'column';
  background:red;
}

.block-text {
  font-size:17px;
}

.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%;
}

.icon-scroll {
  width: 40px;
  height: 70px;
  margin-left: -20px;
  top: 50%;
  margin-top: 10%;
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 25px;
}

.icon-scroll:before {
  content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}


body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.detail-container::-webkit-scrollbar {
  display: none;
}

.detail-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.strap-line p { 
  color:#fff;
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
}

.line-1 {
  font-size: 25px;
}

.line-2 {
  font-size: 40px;
  font-weight:bold;
  line-height: 3.5;
}

@media all and (min-width:160px) and (max-width: 600px) {
  .line-2 {
    line-height: 1.5;
    padding: 20px 0px; 

  }
}

.line-3 {
  font-size: 22px;
}

.typerwriter-bullets {
  transition: all 2.7s ease-in;
}

.typerwriter-bullets ol {
  line-height: 2;
  font-size: 18px;
  color:rgb(240, 240, 240);
  margin:20px 10px 0px 20px;
}

.typerwriter-bullets a {
  color:rgb(142, 125, 255);
  text-decoration: underline;
}